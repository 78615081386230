@use '../utils' as *;

.container {
    padding: 0 rem(25);
    max-width: rem(1110);
    margin: 0 auto rem(46);

    @include breakpoint(large) {
        padding: 0;
    }
}
