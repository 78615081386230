@use '../utils' as *;

.cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(30);

    @include breakpoint(medium) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(large) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.card {

    position: relative;
    overflow: hidden;
    background: var(--card-bg);
    color: var(--text-color);
    padding: rem(25);
    border-radius: rem(5);
    text-align: center;
    flex: 1;
    transition: background 150ms ease-in-out;

    &:hover {
        background: var(--card-hover);
    }

    &--facebook {
        border-top: rem(5) solid var(--facebook);
    }
    &--twitter {
        border-top: rem(5) solid var(--twitter);
        
    }
    &--instagram {
        //border-top: rem(5) solid var(--instagram-start);
            padding-top: rem(30);
        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: rem(5);
            background: linear-gradient(
                225deg, 
                var(--instagram-end), 
                var(--instagram-middle) 50.91%, 
                var(--instagram-start) 100%
                );
        }

    }
    &--youtube {
        border-top: rem(5) solid var(--youtube);
    }
        
    &__platform {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(20);
        margin-bottom: rem(28);
    }

    &__subtitle {
        color: var(--text-color2);
        font-size: rem(14);
        font-weight: 700;
    }

    &__icon {

        margin-right: rem(8);

        &--facebook {
            
        }
        &--twitter {
            
        }
        &--instagram {
            
        }
        &--youtube {
            
        }
    }

    &__username {
        font-size: rem(12);
        font-weight: 700;
    }

    &__followers {
        margin-bottom: rem(25);
    }

    &__count {
        color: var(--text-color);
        font-weight: 700;
        letter-spacing: rem(-2);
        line-height: 1;
        margin-bottom: rem(4);

        &--big {
            font-size: rem(56);
        }

        &--small {
            font-size: rem(32);
        }
    }

    &__label {
        font-size: rem(12);
        letter-spacing: rem(5);
        font-weight: 400;
        color: var(--text-color2);
        text-transform: uppercase;
    }

    &__change {
        display: flex;
        align-items: center;
        font-size: rem(12);
        font-weight: 700;
        justify-content: center;

        &--up {
            color: var(--limegreen);
        }

        &--down {
            color: var(--brightred);
        }

        img {
            margin-right: rem(4);

        }
    }

}